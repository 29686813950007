import { SenioritiesFilters } from "../interfaces/seniorities-filters.interface";

export class LoadWorkersByUnion {
  public static readonly type = "[Seniority Rotation] load by union";
  constructor(
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class UpdateWorkerSeniorityNumber {
  public static readonly type = "[Seniority Rotation] update worker seniority number";
  constructor(
    public id: number,
    public seniorityNumber: number,
    public callbackSuccess: () => void,
    public callbackError: (error) => void
  ) {}
}

export class UpdateWorkersFilters {
	static readonly type = "[Seniority Rotation] update filters";
	constructor(
		public filters: SenioritiesFilters
	) {}
}

export class LoadSeniorityClass {
  static readonly type = "[Seniority Rotation] load seniority class";
  constructor(
    public unionId: number
  ) {}
}